import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserAbilities } from '@/auth/utils';
import userStoreModule from '../userStoreModule';

export default function useProfileUpdateModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const avatarChanged = file => {
    itemLocal.value.avatarFile = file;
  };

  const fetchProfile = () => {
    store
      .dispatch('user/getUserProfile')
      .then(response => {
        const { user } = response.data;
        const ability = getUserAbilities(user.role);
        localStorage.setItem('userData', JSON.stringify({ ...user, ability }));
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      const data = {
        full_name: itemLocal.value.full_name,
        phone: itemLocal.value.phone,
        email: itemLocal.value.email,
        role: itemLocal.value.role._id,
      };

      store
        .dispatch('user/updateProfile', data)
        .then(response => {
          fetchProfile();
          emit('on-item-updated', response.data);
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } catch (error) {

    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
    avatarChanged,
  };
}
