<template>
  <div v-if="userData">
    <gallery-images
      :show="show"
      :photos="[{url: 'https://sgp1.digitaloceanspaces.com/resident/dev-resident/user/1663989668381_member1-1.png'}]"
    />
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Thông tin tài khoản
        </b-card-title>
        <b-button
          v-b-modal.modal-profile-update
          variant="outline-secondary"
        >
          Cập nhật
        </b-button>
      </b-card-header>
      <hr class="mt-0">
      <b-card-body>

        <dl class="row">
          <dt class="col-sm-3">
            Họ tên
          </dt>
          <dd class="col-sm-9">
            {{ userData.full_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Số điện thoại
          </dt>
          <dd class="col-sm-9">
            {{ userData.phone }}

          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3 text-truncate">
            Email
          </dt>
          <dd class="col-sm-9">
            {{ userData.email }}
          </dd>
        </dl>

      </b-card-body>
    </b-card>
    <profile-update-modal
      :item="{...userData, avatarFile: null}"
      @on-item-updated="onProfileUpdated"
    />
    <verify-phone :user-data="userData" />
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BButton, VBModal,
} from 'bootstrap-vue';
import GalleryImages from '@/views/components/GalleryImages.vue';
import ProfileUpdateModal from './ProfileUpdateModal.vue';
import VerifyPhone from './VerifyPhone.vue';

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BCardHeader,
    BCardBody,
    ProfileUpdateModal,
    VerifyPhone,
    GalleryImages,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onProfileUpdated() {
      this.$emit('on-profile-updated');
    },

    previewAvatar() {

    },
  },
};
</script>
