<template>
  <Transition name="bounce">
    <div
      v-if="isShow"
      class="backdrop"
    >
      <div class="header">
        <button
          class="btn-close"
          @click="() => (isShow = false)"
        >
          <feather-icon
            icon="XIcon"
            size="24"
          />
          Đóng
        </button>
        <div class="d-flex mx-1">
          <b-img
            fluid
            src="@/assets/images/icons/image.svg"
            alt="image"
            height="30"
            width="30"
          />
          <div class="ml-1">
            <p class="mb-0 text-dark h4">
              {{ photos[slideIndex].name }}
            </p>
            <p class="mb-0">
              {{ photos[slideIndex].capacity }}
            </p>
          </div>
        </div>
        <button
          class="btn-download"
          @click="onDownLoadImage"
        >
          <feather-icon
            icon="DownloadIcon"
            size="14"
          />
          Tải về
        </button>
      </div>
      <b-carousel
        ref="carouselContent"
        v-model="slideIndex"
        :interval="0"
        class="slide-content d-flex align-items-center"
      >
        <b-carousel-slide
          v-for="(photo,index) in photos"
          :key="index"
        >
          <template
            #img
          >
            <pinch-scroll-zoom
              :key="`${widthContainer}-${heightContainer}`"
              ref="zoomer"
              :scale="scale"
              :min-scale="minScale"
              :max-scale="maxScale"
              :width="widthContainer"
              :height="heightContainer"
              @scaling="scalingHandler"
            >
              <img
                class="img-fluid img-item"
                :src="photo.url"
                alt="image slot"
              >
            </pinch-scroll-zoom>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="control-buttons border rounded">
        <feather-icon
          v-b-tooltip.hover.top="'Thu nhỏ'"
          icon="ZoomInIcon"
          size="25"
          @click="onZoomIn"
        />
        <feather-icon
          v-b-tooltip.hover.top="'Phóng to'"
          icon="ZoomOutIcon"
          size="25"
          @click="onZoomOut"
        />
        <feather-icon
          v-b-tooltip.hover.top="'Tải về'"
          icon="DownloadIcon"
          size="25"
          @click="onDownLoadImage"
        />
        <template v-if="photos.length > 1">
          <feather-icon
            v-b-tooltip.hover.top="'Trước'"
            icon="ArrowLeftIcon"
            size="25"
            @click="onPrev"
          />
          <feather-icon
            v-b-tooltip.hover.top="'Sau'"
            icon="ArrowRightIcon"
            size="25"
            @click="onNext"
          />
        </template>
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  BImg,
  VBTooltip,
  BTooltip,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue';
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";

export default {
  components: {
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    BCarousel,
    BCarouselSlide,
    PinchScrollZoom,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isShow: false,
    slideIndex: 0,
    scale: 1,
    minScale: 0.5,
    maxScale: 3,
    widthContainer: 0,
    heightContainer: 0,
  }),
  watch: {
    isShow() {
      this.$nextTick(() => {
        if (this.$refs.carouselContent) {
          this.heightContainer = this.$refs.carouselContent.$el.clientHeight;
          this.widthContainer = this.$refs.carouselContent.$el.clientWidth;
        }
      });
    },
    show(val) {
      this.isShow = val;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$nextTick(() => {
        if (this.$refs.carouselContent) {
          this.heightContainer = this.$refs.carouselContent.$el.clientHeight;
          this.widthContainer = this.$refs.carouselContent.$el.clientWidth;
        }
      });
    });
  },
  methods: {
    onPrev() {
      this.scale = 1;
      this.$refs.carouselContent.prev();
    },
    onNext() {
      this.scale = 1;
      this.$refs.carouselContent.next();
    },
    onZoomIn() {
      if (this.scale < 3) { this.scale += 0.5; }
    },
    onZoomOut() {
      if (this.scale > 0.5) { this.scale -= 0.5; }
    },
    async onDownLoadImage() {
      const photo = this.photos[this.slideIndex];
      const image = await fetch(photo.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = photo.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    scalingHandler(e) {
      this.scale = e.scale;
    },
  },
};
</script>
<style lang="scss">
  .carousel-inner {
    height: 100%;

    .carousel-item {
      height: 100%;
    }
  }
  .pinch-scroll-zoom {
    &__content {
      display: flex;
    }
  }
</style>
<style lang="scss" scoped>
    .bounce-enter-active {
      animation: bounce-in 0.5s;
    }

    .bounce-leave-active {
      animation: bounce-in 0.5s reverse ease-in-out;
    }

    @keyframes bounce-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .backdrop{
      position: absolute;
      z-index: 999;
      width: 100%;
      height: 100%;
      background-color: #e6e6e6;
    }

    .header {
      display: flex;
      width: 100%;
      height: 65px;
      align-items: center;
      padding: 10px;
      background-color: white;
      border-bottom: 1px solid gainsboro;
    }

    .btn-download {
      display: flex;
      align-items: center;
      margin-left: auto;
      border: 1px solid gainsboro;
      border-radius: 5px;
      padding: 5px 12px;
      background-color: white;
      gap: 5px;

      &:hover {
        background-color: #80808045;
      }
    }

    .btn-close {
      display: flex;
      align-items: flex-end;
      position: relative;
      border: 0;
      background-color: white;
      gap: 10px;
      font-size: 16px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 8px 11px;

      &:hover {
        background-color: #80808045;
      }

      &:after {
        content: "";
        width: 1px;
        height: 45px;
        position: absolute;
        right: -10px;
        top: -3px;
        background-color: rgb(194, 194, 194);
      }
    }

    .slide-content {
      height: calc(100% - 65px);
      width: 100%;
    }

    .img-item {
      margin: auto;
      overflow: auto;
      user-select: none;
      transition: all 0.3s ease 0s;
    }

    .control-buttons {
      display: flex;
      gap: 15px;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      padding: 10px;
      box-shadow: 0 0 10px grey;

      svg {
        cursor: pointer;
        margin: 3px;
        padding: 2px;
        border-radius: 5px;

        &:hover {
          color: black;
          background-color: gainsboro;
        }
      }
    }
</style>
